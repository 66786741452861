import React, { Children } from "react";
import { Link } from "gatsby";
import { useEffect } from "react";
import PropTypes from "prop-types";
import { gsap } from "gsap";
/** @jsx jsx */
import { jsx } from "theme-ui";
import { FaChevronDown } from "react-icons/fa";

import isMobile from "../../utilities/isMobile";
import Navigation from ".";

const NavElements = ({ navItems }) => {
  if (!Array.isArray(navItems) || navItems.length === 0) {
    return null;
  }
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", () => {
        let navChildren = document.getElementsByClassName("nav-children");
        navChildren = Array.prototype.slice.call(navChildren);
        resetLists(navChildren);
      });
    }
  });

  const navHoverTl = gsap.timeline();
  const onNavHover = (target) => {
    if (target.tagName !== "DIV") {
      do {
        target = target.parentElement;
      } while (target.tagName !== "DIV");
    }
    const el = target.getElementsByTagName("UL")[0];
    if (navHoverTl.isActive()) {
      const duration = navHoverTl.totalDuration();
      navHoverTl.seek(duration, false);
    }
    navHoverTl
      .to(el, {
        opacity: 0,
        visibility: "visible",
        duration: 0,
      })
      .to(el, {
        opacity: 1,
        duration: 0.2,
      });
  };

  const onNavLeaveHover = (target) => {
    if (target.tagName !== "DIV") {
      do {
        target = target.parentElement;
      } while (target.tagName !== "DIV");
    }
    const el = target.getElementsByTagName("UL")[0];
    navHoverTl
      .to(el, {
        opacity: 0,
        duration: 0.2,
      })
      .to(el, {
        visibility: "",
        opacity: 1,
        duration: 0,
      });
  };

  const resetLists = (lists) => {
    if (!Array.isArray(lists)) {
      return;
    }
    lists.forEach((list) => {
      list.style.height = "";
      list.style.visibility = "";
      list.setAttribute("data-collapsed", "true");
      const svg = list.parentElement.getElementsByTagName("svg")[0];
      if (typeof svg !== "undefined") {
        svg.style.transform = "";
      }
    });
  };

  const onChevronClick = (e) => {
    e.preventDefault();
    if (!isMobile(3)) {
      return;
      console.log("not mobile");
    }
    console.log("is mobile");
    let parentDiv = e.target;
    let chevron;
    do {
      if (parentDiv.tagName === "svg") {
        chevron = parentDiv;
      }
      parentDiv = parentDiv.parentElement;
    } while (parentDiv.tagName !== "DIV");
    const targetList = parentDiv.getElementsByTagName("UL")[0];
    const listHeight = targetList.scrollHeight;

    const tl = gsap.timeline({ defaults: { ease: "sine.out" } });
    const cleanTl = () => tl.kill();

    const resetList = () => {
      resetLists([targetList]);
    };

    if (targetList.getAttribute("data-collapsed") === "true") {
      tl.to(targetList, {
        height: listHeight,
        duration: 0.3,
      }).to(
        chevron,
        {
          rotate: 180,
          duration: 0.3,
          onComplete: cleanTl,
        },
        "<"
      );
      targetList.setAttribute("data-collapsed", "false");
    } else {
      tl.to(targetList, {
        height: 0,
        onComplete: resetList,
        duration: 0.3,
      }).to(
        chevron,
        {
          rotate: 0,
          duration: 0.3,
          onComplete: cleanTl,
        },
        "<"
      );
      targetList.setAttribute("data-collapsed", "true");
    }
  };

  const renderNavChild = (navChild) => {
    return (
      <li sx={{ px: 2 }}>
        <Link to={navChild.path}>{navChild.name}</Link>
      </li>
    );
  };

  const renderNavChildren = (navItem) => {
    const navRenderchild = (child, key) => {
      return (
        <li
          key={key}
          sx={{
            py: 1,
            px: [0, 0, 0, 0, 1],
          }}
        >
          <Link
            to={child.path}
            sx={{
              color: "text",
              textDecoration: "none",
              "&:visited": {
                color: "text",
              },
              "&:hover": {
                color: ["text", "text", "text", "text", "primary"],
              },
            }}
          >
            {child.name}
          </Link>
        </li>
      );
    };

    const navChildren = [];
    navItem.children.forEach((child, key) => {
      navChildren.push(navRenderchild(child, key));
    });

    return (
      <ul
        className="nav-children"
        data-collapsed="true"
        sx={{
          height: [0, 0, 0, 0, "auto"],
          my: [0, 0, 0, 0, 2],
          ml: [2, 2, 2, 2, 0],
          width: ["auto", "auto", "auto", "auto", "14rem"],
          position: [
            "relative",
            "relative",
            "relative",
            "relative",
            "absolute",
          ],
          zIndex: "1000",
          top: [0, 0, 0, 0, 7],
          px: [0, 0, 0, 0, 2],
          backgroundColor: "background",
          visibility: ["visible", "visible", "visible", "visible", "hidden"],
          listStyle: "none",
          paddingInlineStart: [0],
          display: "block",
          border: ["none", "none", "none", "none", "1px solid rgba(0,0,0,.15)"],
          borderTop: "none",
        }}
      >
        {navChildren}
      </ul>
    );
  };

  const renderNavItems = () => {
    const navList = [];

    navItems.forEach((navItem, key) => {
      navList.push(renderNavItem(navItem, key));
    });
    return navList;
  };

  const renderNavItem = (navItem, key) => {
    const hasChild =
      "children" in navItem &&
      Array.isArray(navItem.children) &&
      navItem.children.length > 0;
    return (
      <div
        key={key}
        sx={{
          mx: [2],
          mb: [2, 2, 2, 2, 0],
          overflow: "hidden",
          py: [0, 0, 0, 0, 3],
          px: [2, 2, 2, 2, 0],
          display: "inline-block",
          width: ["100%", "100%", "100%", "100%", "auto"],
        }}
        className="nav-element"
        onMouseEnter={(e) => {
          if (hasChild) {
            onNavHover(e.target);
          }
        }}
        onMouseLeave={(e) => {
          if (hasChild) {
            onNavLeaveHover(e.target);
          }
        }}
      >
        <span
          sx={{
            mr: [0, 0, 0, 0, 2],
            display: ["flex", "flex", "flex", "flex", "inline-block"],
            alignItems: "center",
          }}
        >
          <Link
            sx={{
              color: ["primary", "primary", "primary", "primary", "background"],
              flexGrow: 1,
              "&:hover": {
                color: [
                  "primary",
                  "primary",
                  "primary",
                  "primary",
                  "lightGray",
                ],
              },
              textDecoration: "none",
              mr: [0, 0, 0, 0, 2],
            }}
            to={navItem.path}
            activeClassName="active-nav"
          >
            {navItem.name}
          </Link>
          {hasChild && (
            <a href="#" onClick={onChevronClick}>
              <FaChevronDown
                sx={{
                  color: [
                    "primary",
                    "primary",
                    "primary",
                    "primary",
                    "background",
                  ],
                  float: ["right", "right", "right", "right", "none"],
                  verticalAlign: "middle",
                }}
              />
            </a>
          )}
        </span>
        {hasChild && renderNavChildren(navItem)}
      </div>
    );
  };

  return (
    <div
      id="nav"
      sx={{
        display: ["block", "block", "block", "block", "flex"],
        flexDirection: "row",
        justifyContent: "start",
        py: [4, 4, 4, 4, 0],
      }}
    >
      {renderNavItems()}
    </div>
  );
};

NavElements.propTypes = {
  navItems: PropTypes.array.isRequired,
};

export default NavElements;
