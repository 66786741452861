import { Link } from "theme-ui";

const formatNavData = (navData) => {
  if (!Array.isArray(navData) || navData === 0) {
    console.log("formatNavData did not receive array");
    return [];
  }

  const navItems = [];

  navData.forEach((itemData) => {
    navItems.push(buildNavItem(itemData));
  });
  return navItems;
};

const getPath = (navElement) => {
  if (typeof navElement === "undefined") {
    return null;
  }
  if (navElement.useLink === true) {
    return navElement.link;
  }

  if (navElement.slug.slug != null) {
    return `/${navElement.slug.slug.current}`;
  }
  return "/";
};

const buildNavChildren = (childData) => {
  const navChildren = [];

  childData.forEach((child) => {
    navChildren.push({
      name: child.childLinkText,
      path: getPath(child.navigationChild),
    });
  });
  return navChildren;
};

const buildNavItem = (itemData) => {
  const navItem = {
    name: itemData.parentLinkText,
    path: getPath(itemData.navigationElement),
  };

  if (
    typeof itemData.navChildren !== "undefined" &&
    Array.isArray(itemData.navChildren) &&
    itemData.navChildren.lenght !== 0
  ) {
    navItem.children = buildNavChildren(itemData.navChildren);
  }

  return navItem;
};

export default formatNavData;
