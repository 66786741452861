import React from 'react';
import { FaBorderNone } from 'react-icons/fa';
import { gsap } from 'gsap';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import { FaChevronRight } from 'react-icons/fa';

const PonceButton = ({ children, arrow = false, variant = 'primary' }) => {
  const buttonEnter = (e) => {
    const buttonTl = gsap.timeline({ defaults: { ease: 'sine.out' } });
    const kill = () => {
      buttonTl.kill();
    };
    const button = e.target;
    const icon = button.getElementsByClassName('button-arrow')[0];
    console.log('icon', icon);
    buttonTl.to(icon, {
      x: '4px',
      duration: 0.1,
      onComplete: kill,
    });
  };

  const buttonLeave = (e) => {
    const buttonTl = gsap.timeline({ defaults: { ease: 'sine.out' } });
    const kill = () => {
      buttonTl.kill();
    };
    const button = e.target;
    const icon = button.getElementsByClassName('button-arrow')[0];
    console.log('icon', icon);
    buttonTl.to(icon, {
      x: '0px',
      duration: 0.1,
      onComplete: kill,
    });
  };

  return (
    <button
      onMouseEnter={(e) => {
        if (arrow === true) {
          buttonEnter(e);
        }
      }}
      onMouseLeave={(e) => {
        if (arrow === true) {
          buttonLeave(e);
        }
      }}
      sx={{
        variant: `buttons.${variant}`,
        borderRadius: '1px',
        boxSizing: 'border-box',
        border: 'none',
        outlineColor: 'muted',
        outlineWidth: '1px',
        fontSize: 2,
        lineHeight: '1',
        fontFamily: 'body',
        pr: arrow ? 6 : 5,
        py: 3,
        pl: 5,
        position: 'relative',
      }}
    >
      <span sx={{ mr: arrow ? 1 : 0 }}>{children}</span>
      {arrow && (
        <FaChevronRight
          className="button-arrow"
          sx={{
            position: 'absolute',
            right: 4,
          }}
        />
      )}
    </button>
  );
};

export default PonceButton;
