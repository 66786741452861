import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';
import { FaChevronDown } from 'react-icons/fa';

import Container from '../Container';
import NavWrapper from './NavWrapper';
import NavElements from './NavElements';
import isMobile from '../../utilities/isMobile';
import formatNavData from './formatNavData';

const Navigation = (props) => {
  const { background, mobileLogo, mobileLogoAlt } = props;
  const data = useStaticQuery(graphql`
    query {
      navData: sanityNavigation(slug: { current: { eq: "main-menu" } }) {
        navElements {
          parentLinkText
          navigationElement {
            slug {
              ... on SanityService {
                slug {
                  current
                }
              }
              ... on SanitySiteSection {
                slug {
                  current
                }
              }
            }
            useLink
            link
          }
          navChildren {
            childLinkText
            navigationChild {
              useLink
              link
              slug {
                ... on SanityService {
                  name
                  slug {
                    current
                  }
                }
                ... on SanitySiteSection {
                  slug {
                    current
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const navItems = formatNavData(data.navData.navElements);
  return (
    <nav
      css={{
        overflow: ['hidden', 'hidden', 'hidden', 'hidden', 'visible'],
      }}
    >
      <NavWrapper mobileLogo={mobileLogo} mobileLogoAlt={mobileLogoAlt}>
        <Container id="container">
          <NavElements navItems={navItems} />
        </Container>
      </NavWrapper>
    </nav>
  );
};

Navigation.propTypes = {
  background: PropTypes.string.isRequired,
  navData: PropTypes.object.isRequired,
  mobileLogo: PropTypes.object,
  mobileLogoAlt: PropTypes.string,
};

export default Navigation;
