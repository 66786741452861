import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function SEO({ description, lang, title, meta, metaImage }) {
  const { settings } = useStaticQuery(
    graphql`
      query {
        settings: sanitySiteSettings(name: { eq: "Ponce D. Moody Funding" }) {
          description
          name
          primaryPhone
          primaryAddress {
            streetAddressOne
            streetAddressTwo
            city
            state
            zip
          }
          seotitle
          siteUrl
          siteAuthor
          description
          metaImage {
            image {
              asset {
                fixed {
                  src
                }
              }
            }
            imageHeight
            imageWidth
          }
          primaryLogo {
            image {
              asset {
                fixed {
                  src
                }
              }
            }
          }
        }
      }
    `
  );

  const metaDescription = description || settings.description;
  const url = settings.siteUrl;
  const name = settings.name;
  const logoURL = settings.primaryLogo.image.asset.fixed;
  const address = settings.primaryAddress;

  if (
    !metaImage ||
    typeof metaImage === 'undefined' ||
    typeof metaImage.height === 'undefined' ||
    typeof metaImage.width === 'undefined'
  ) {
    const sanityMetaImage = settings.metaImage;
    metaImage = {
      src: sanityMetaImage.image.asset.fixed.src,
      height: sanityMetaImage.imageHeight,
      width: sanityMetaImage.imageWidth,
    };
  }

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: url,
    inLanguage: 'en',
    mainEntityOfPage: url,
    description: metaDescription,
    name: name,
    type: 'ProfessionalService',
    copyrightYear: new Date().getFullYear(),
    datePublished: '2020-08-01T10:30:00+01:00',
    dateModified: new Date(),
    image: logoURL,
    address: {
      type: 'PostalAddress',
      addressLocality: address.city,
      addressRegion: address.state,
      postalCode: address.zip,
      streetAddress: `${address.streetAddressOne} ${address.streetAddressTwo}`,
    },
    priceRange: '$$',
    telephone: '(919) 760-6597',
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: 'description',
          content: metaDescription,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: metaDescription,
        },
        {
          property: 'og:type',
          content: 'website',
        },
      ]
        .concat(
          metaImage
            ? [
                {
                  property: 'og:image',
                  content: metaImage.src,
                },
                {
                  property: 'og:image:width',
                  content: metaImage.width,
                },
                {
                  property: 'og:image:height',
                  content: metaImage.height,
                },
                {
                  name: 'twitter:card',
                  content: 'summary_large_image',
                },
              ]
            : [
                {
                  name: 'twitter:card',
                  content: 'summary',
                },
              ]
        )
        .concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgWebPage)}
      </script>
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  description: '',
};

export default SEO;
