export default {
  navItems: [
    {
      name: 'Home',
      path: '/',
    },
    {
      name: 'Commercial Real Estate',
      path: '/commercial-real-estate',
      children: [
        {
          name: 'New Project Financing',
          path: '/commercial-real-estate/new-project-financing',
        },
        {
          name: 'Bridge Financing',
          path: '/commercial-real-estate/bridge-financing',
        },
        {
          name: 'Refinancing',
          path: '/commercial-real-estate/refinancing',
        },
        {
          name: 'Equity Partnerships',
          path: '/commercial-real-estate/equity-partnerships',
        },
        {
          name: 'Farm and Ranch Loans',
          path: '/commercial-real-estate/farm-and-ranch-loans',
        },
      ],
    },
    {
      name: 'Farm and Ranch',
      path: '/farm-and-ranch',
    },
    {
      name: 'Energy Projects',
      path: '/energy-projects',
    },
    {
      name: 'Factoring',
      path: '/factoring',
    },
    {
      name: 'Fix and Flip',
      path: '/fix-and-flip',
    },
    {
      name: 'Credit Cards',
      path: '/credit-cards',
    },
  ],
};
