import React from 'react';
import { useState, useEffect, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { gsap } from 'gsap';
/** @jsx jsx */
import { jsx } from 'theme-ui';
import isMobile from '../../utilities/isMobile';

import MobileMenuBar from '../navigation/MobileMenuBar';

const NavWrapper = ({ children, mobileLogo, mobileLogoAlt }) => {
  const [navMinHeight, setNavMinHeight] = useState('');

  useEffect(() => {
    const activeLink = document.getElementById('active-link');
    setNavMinHeight(calculateNavHeight());
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', () => {
        mobileNavReset();
        setNavMinHeight(calculateNavHeight());
        if (isMobile(3)) {
          setScroll();
        } else {
          unSetScroll();
        }
      });
      if (isMobile(3)) {
        setScroll();
      }
    }
  }, []);

  const setScroll = () => {
    window.addEventListener('scroll', onScroll, true);
    console.log('scroll set');
  };

  const unSetScroll = () => {
    window.removeEventListener('scroll', onScroll, true);
    console.log('scroll unset');
  };

  const onScroll = () => {
    const open = document
      .getElementById('nav-wrapper')
      .getAttribute('data-open');
    if (open === 'true' && window.scrollY > 50) {
      closeMobileNav();
    }
  };

  const getPageHeight = () => {
    var body = document.body,
      html = document.documentElement;

    var height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    return height;
  };

  const calculateNavHeight = () => {
    if (typeof window === 'undefined') {
      return 0;
    }
    const navMenuHeight = document.getElementById('mobile-menu-bar')
      .offsetHeight;
    const viewHeight = Math.max(
      document.documentElement.clientHeight || 0,
      window.innerHeight || 0
    );
    const navHeight = viewHeight - navMenuHeight;
    return navHeight;
  };

  const mobileNavReset = () => {
    const navWrapper = document.getElementById('nav-wrapper');
    const navBackground = document.getElementById('nav-background');

    navWrapper.style.display = '';
    navWrapper.style.transform = '';
    navWrapper.setAttribute('data-open', 'false');
    navBackground.style.visibility = '';
    navBackground.style.opacity = '';
  };

  const mobileMenuClick = (e) => {
    const open = document
      .getElementById('nav-wrapper')
      .getAttribute('data-open');
    if (open === 'true') {
      closeMobileNav();
    } else {
      openMobileNav();
    }
  };

  const openMobileNav = () => {
    const navWrapper = document.getElementById('nav-wrapper');
    const open = gsap.timeline({ defaults: { ease: 'sine.out' } });
    const vw = Math.max(
      document.documentElement.clientWidth || 0,
      window.innerWidth || 0
    );
    const clean = () => {
      open.kill();
      navWrapper.setAttribute('data-open', 'true');
    };

    open
      .to('#nav-wrapper', { display: 'block', duration: 0, x: 0 })
      .to('#nav-background', { visibility: 'visible', duration: 0 })
      .to('#nav-wrapper', {
        x: '-250px',
        duration: 0.3,
        onComplete: clean,
      })
      .to(
        '#nav-background',
        {
          opacity: 0.7,
          duration: 0.5,
        },
        '<'
      );
  };

  const closeMobileNav = () => {
    const navWrapper = document.getElementById('nav-wrapper');
    const close = gsap.timeline({ defaults: { ease: 'sine.out' } });
    const clean = () => {
      close.kill();
      mobileNavReset();
    };
    close
      .to('#nav-wrapper', { display: 'block', duration: 0 })
      .to('#nav-wrapper', {
        x: '250px',
        duration: 0.5,
        onComplete: clean,
      })
      .to(
        '#nav-background',
        {
          opacity: 0,
          duration: 0.5,
        },
        '<'
      );
  };

  return (
    <>
      <MobileMenuBar
        mobileMenuClick={mobileMenuClick}
        mobileLogo={mobileLogo}
        mobileLogoAlt={mobileLogoAlt}
      />
      <div
        id="nav-background"
        sx={{
          minHeight: [
            navMinHeight,
            navMinHeight,
            navMinHeight,
            navMinHeight,
            'auto',
          ],
          visibility: 'hidden',
          opacity: 0,
          backgroundColor: 'text',
          width: '100%',
          position: 'absolute',
          zIndex: 999,
        }}
      ></div>
      <div
        id="nav-wrapper"
        data-open="false"
        sx={{
          right: ['-250px', '-250px', '-250px', '-250px', 'initial'],
          display: ['none', 'none', 'none', 'none', 'block'],
          maxWidth: ['250px', '250px', '250px', '250px', '100%'],
          width: ['80%', '80%', '80%', '80%', '100%'],
          minHeight: [
            navMinHeight,
            navMinHeight,
            navMinHeight,
            navMinHeight,
            'inherit',
          ],
          position: [
            'absolute',
            'absolute',
            'absolute',
            'absolute',
            'relative',
          ],
          backgroundColor: [
            'background',
            'background',
            'background',
            'background',
            'primary',
          ],
          zIndex: 1000,
          borderLeft: [
            '1px solid rgba(0,0,0,.15)',
            '1px solid rgba(0,0,0,.15)',
            '1px solid rgba(0,0,0,.15)',
            '1px solid rgba(0,0,0,.15)',
            'none',
          ],
          borderBottom: [
            '1px solid rgba(0,0,0,.15)',
            '1px solid rgba(0,0,0,.15)',
            '1px solid rgba(0,0,0,.15)',
            '1px solid rgba(0,0,0,.15)',
            'none',
          ],
        }}
      >
        {children}
      </div>
    </>
  );
};

export default NavWrapper;
