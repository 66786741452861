import * as React from 'react';
/** @jsx jsx */
import { jsx, useThemeUI } from 'theme-ui';

export default ({ children, style, sx }) => {
  const theme = useThemeUI().theme;
  return (
    <div
      sx={{
        maxWidth: ['100%', ...theme.breakpoints],
        mx: 'auto',
        px: 3,
        ...style,
      }}
    >
      {children}
    </div>
  );
};
