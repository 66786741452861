import theme from '../gatsby-plugin-theme-ui';

export default (mobileBreakpoint = 3) => {
  if (typeof window === 'undefined') {
    return false;
  }

  const viewWidth = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  if (viewWidth > parseInt(theme.breakpoints[mobileBreakpoint], 10)) {
    return false;
  } else {
    return true;
  }
};
