import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import { IoMdMenu } from 'react-icons/io';
/** @jsx jsx */
import { Flex, jsx } from 'theme-ui';
import Container from '../Container';

const MobileMenuBar = ({
  mobileMenuClick,
  backgroundColor = 'primary',
  color = 'background',
}) => {
  const {
    settings: { whiteLogo },
  } = useStaticQuery(graphql`
    query {
      settings: sanitySiteSettings(name: { eq: "Ponce D. Moody Funding" }) {
        whiteLogo {
          alt
          image {
            asset {
              fluid(maxWidth: 200) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);

  const mobileLogo = whiteLogo.image.asset.fluid;
  const mobileLogoAlt = whiteLogo.alt;

  const MobileMenuWrapper = ({ children }) => {
    return <div sx={{ backgroundColor, color }}>{children}</div>;
  };

  const renderLogo = () => {
    if (typeof mobileLogo === 'undefined') {
      return;
    }

    return (
      <div sx={{ height: 'auto', width: '200px' }}>
        <Link to="/">
          <Img fluid={mobileLogo} alt={mobileLogoAlt || ''} />
        </Link>
      </div>
    );
  };

  return (
    <MobileMenuWrapper>
      <div
        id="mobile-menu-bar"
        sx={{
          display: ['block', 'block', 'block', 'block', 'none'],
          backgroundColor: 'primary',
          width: '100%',
        }}
      >
        <Container
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            py: 2,
          }}
        >
          <div
            sx={{
              flexGrow: 1,
            }}
          >
            {renderLogo()}
          </div>
          <div
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <a
              href="#"
              onClick={mobileMenuClick}
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <IoMdMenu
                sx={{
                  color: 'background',
                  fontSize: 5,
                }}
              />
            </a>
          </div>
        </Container>
      </div>
    </MobileMenuWrapper>
  );
};

MobileMenuBar.propTypes = {
  mobileMenuClick: PropTypes.func.isRequired,
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  mobileLogo: PropTypes.object,
  mobileLogoAlt: PropTypes.string,
};

export default MobileMenuBar;
