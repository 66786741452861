import React from 'react';
import Img from 'gatsby-image';
/** @jsx jsx */
import { Flex, jsx, Styled } from 'theme-ui';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Container from './Container';

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      settings: sanitySiteSettings(name: { eq: "Ponce D. Moody Funding" }) {
        primaryPhone
        primaryLogo {
          alt
          image {
            asset {
              fluid(maxWidth: 350) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  `);
  //console.log('data', data);
  const logo = data.settings.primaryLogo.image.asset.fluid;
  const logoAlt = data.settings.primaryLogo.alt;
  const phone = data.settings.primaryPhone;
  return (
    <header
      sx={{
        display: ['none', 'none', 'none', 'none', 'block'],
      }}
    >
      <Container>
        <div
          sx={{
            display: 'flex',
          }}
        >
          <div
            sx={{
              width: '350px',
              height: 'auto',
              py: 3,
            }}
          >
            <Link to="/">
              <Img fluid={logo} alt={logoAlt} />
            </Link>
          </div>
          <div sx={{ flexGrow: 1 }}></div>
          <div
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Styled.h1 as="span">{phone}</Styled.h1>
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
