import React from "react";
import { Link } from "gatsby";
/** @jsx jsx */
import { jsx, Styled } from "theme-ui";
import Container from "./Container";

const Footer = () => {
  const date = new Date();
  return (
    <footer sx={{ backgroundColor: "text", py: 3 }}>
      <Container>
        <Styled.p sx={{ color: "background" }}>
          <span sx={{ mr: 4 }}>
            &#169; {date.getFullYear()} Ponce Moody Funding
          </span>
          <Link sx={{ variant: "reverseLink", mr: 4 }} to={`/terms-of-service`}>
            Terms of Service
          </Link>
          <Link sx={{ variant: "reverseLink", mr: 4 }} to={`/privacy-policy`}>
            Privacy Policy
          </Link>
          <Link sx={{ variant: "reverseLink", mr: 4 }} to={`/about-us`}>
            About Us
          </Link>
        </Styled.p>
      </Container>
    </footer>
  );
};

export default Footer;
