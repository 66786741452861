import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
/** @jsx jsx */
import { jsx, Input, Textarea, Styled } from 'theme-ui';
import PonceButton from './PonceButton';

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.submitForm = this.submitForm.bind(this);
    this.state = {
      status: '',
    };
  }

  submitForm(ev) {
    ev.preventDefault();
    const form = ev.target;
    const data = new FormData(form);
    const xhr = new XMLHttpRequest();
    xhr.open(form.method, form.action);
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.onreadystatechange = () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) return;
      if (xhr.status === 200) {
        form.reset();
        this.setState({ status: 'SUCCESS' });
      } else {
        this.setState({ status: 'ERROR' });
      }
    };
    xhr.send(data);
  }

  renderForm() {
    return (
      <StaticQuery
        query={graphql`
          query {
            settings: sanitySiteSettings(
              name: { eq: "Ponce D. Moody Funding" }
            ) {
              contactFormHeading
            }
          }
        `}
        render={(data) => {
          const headline = data.settings.contactFormHeading;
          return (
            <>
              <Styled.h3 as="p" sx={{ mb: 2, mt: 0 }}>
                {headline}
              </Styled.h3>
              <Input name="name" placeholder="Name*" required="required" />
              <Input name="phone" placeholder="Phone" />
              <Input name="email" placeholder="Email*" required="required"/>
              <Textarea name="message" placeholder="Message" rows="6" />
              <PonceButton arrow={true} variant="secondary">
                Submit
              </PonceButton>
            </>
          );
        }}
      />
    );
  }

  renderThankYouMessage() {
    return (
      <div>
        <Styled.h5 as="span" sx={{ color: 'secondary', my: 3 }}>
          Thank's for your response. We'll be in touch soon.
        </Styled.h5>
      </div>
    );
  }

  renderErrorMessage() {
    return (
      <div sx={{ my: 3 }}>
        <Styled.h5 as="span" sx={{ color: 'red' }}>
          Oops. Something went wrong. Please try again later or call us at the
          number above. We apologize for the inconvenience.
        </Styled.h5>
      </div>
    );
  }

  render() {
    const { style } = this.props;
    const { status } = this.state;
    return (
      <form
        sx={{
          ...style,
        }}
        onSubmit={this.submitForm}
        action="https://formkeep.com/f/6f7865eb3abb"
        method="POST"
      >
        {status === 'SUCCESS'
          ? this.renderThankYouMessage()
          : this.renderForm()}
        {status === 'ERROR' && this.renderErrorMessage()}
      </form>
    );
  }
}
